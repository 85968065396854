<template>

    <div class="container-fluid">
        <div class="row no-gutters">
            <div class="col-12">
                <div class="card bd-l-0-force bd-t-0-force bd-r-0-force">
                <div class="card-body bg-info pd-y-50">
                    <div class="row no-gutters">
                        <div class="col-md-6 mg-t-20">
                            <div class="d-flex align-items-center">
                            <div class="mr-3">										
                                <span class="avatar avatar-lg avatar-online pd-b-20">
                                <img src="https://scola-uploader.sgp1.digitaloceanspaces.com/upload/431f98add25ded6563ab086d/logo_itb_1024.png" class="img-fluid wd-100" alt="">
                                </span>
                            </div>
                            <div class="mg-b-0">
                                <h5 class="tx-gray-100 tx-15 mg-b-0">{{ userdata.name }}</h5>
                                <p class="mg-b-10 tx-gray-300">{{ userdata.username }}</p>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-4 mg-t-10 mg-l-auto">
                            <ul class="list-unstyled tx-gray-100 mb-0">
                            <li class="mt-2"><i class="fa fa-check-square-o mr-2 font-18"></i> <b>Pengutamaan </b>: {{ profil[0].name }}</li>
                            <li class="mt-2"><i class="fa fa-calendar mr-2 font-18"></i> <b>Awal Studi </b>: {{ profil[0].awal_studi }}</li>
                            <li class="mt-2"><i class="fa fa-calendar mr-2 font-18"></i> <b>Akhir Studi </b>: {{ profil[0].batas_studi }}</li>
                            <li class="mt-2"><i class="fa fa-phone mr-2 font-18"></i> <b>No Hp </b>: {{ userdata.phone }}</li>
                            <li class="mt-2"><i class="fa fa-envelope-o mr-2 font-18"></i> <b>Email </b>: {{ userdata.email }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                </div>
                <div class="card bd-l-0-force bd-t-0-force bd-r-0-force bd-b-0-force">
                <div class="card-body bg-light">
                    <div class="row no-gutters">
                        <div class="col-12">
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div class="row no-gutters mg-b-20">
            <div class="col-md-12 col-lg-7 col-xl-8">
                <div class="card bd-t-0-force bd-b-0-force pd-t-20">
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="my-activity" role="tabpanel" aria-labelledby="nav-activity-tab">
                        <div class="card-body pd-0 pd-x-20">
                            <h6>Pembimbing</h6>
                            <div class="publisher publisher-multi mg-b-20">
                            <div class="flexbox bd p-2">
                                {{ profil[0].pembimbing && profil[0].pembimbing[0] ? profil[0].pembimbing[0] : '-' }}<br>
                                {{ profil[0].pembimbing && profil[0].pembimbing[1] ? profil[0].pembimbing[1] : '-' }}<br>
                                {{ profil[0].pembimbing && profil[0].pembimbing[2] ? profil[0].pembimbing[2] : '-' }}
                            </div>
                            </div>
                            <h6>Judul</h6>
                            <div class="publisher publisher-multi mg-b-20">
                            <div class="flexbox bd p-2">
                                {{ profil[0].judul }}
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="col-lg-5 col-xl-4 hidden-sm">
                <div class="card bg-light bd-l-0-force bd-t-0-force bd-r-0-force">
                <div class="card-body pd-b-0">
                    <div class="p-2 mb-3">
                        <div class="mt-3 mb-3 row justify-content-center">
                            <div class="card shadow-none pd-20 mx-auto wd-300 text-center bd-1 align-self-center">
                            <div class="text-center">
                            <img src="https://scola-uploader.sgp1.digitaloceanspaces.com/upload/431f98add25ded6563ab086d/logo_itb_1024.png" class="rounded-circle img-fluid wd-60 mg-y-20" alt="">			   
                            </div>
                            <div class="clearfix">
                            <form @submit.prevent="submitForm" enctype="multipart/form-data">
                                    <div class="form-group input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text"><i class="fa fa-lock"></i></div>
                                        </div>
                                        <input type="password" v-model="form.password" class="form-control" placeholder="password..." required="">
                                    </div>
                                    <div class="form-group input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text"><i class="fa fa-lock"></i></div>
                                        </div>
                                        <input type="password" @keyup="matchPassword()" v-model="form.password_confirm" class="form-control" placeholder="ulang password..." required="">
                                    </div>
                                        <span v-if="checkPassword == 2"><a class="text-danger">Password Tidak Sesuai</a></span>
                                        <span v-if="checkPassword == 1"><a class="text-success">Password Sesuai</a></span>
                                   <div class="input-group mg-y-20">
                                        <button class="col-12 btn btn-warning">Ubah Password</button>
                                    </div>
                            </form>
                            </div>
                        </div>
                        
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    // @ is an alias to /src
    import axios from 'axios'
    import { mapState } from 'vuex';

    export default {
        name: 'Beranda',
        components: {
            
        },
        metaInfo: {
            title: 'FTSL',
            titleTemplate: '%s - Profil Mahasiswa'
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                search: "",
                profil: [],
                fetching: true,
                checkPassword: 0,
                form: {
                    password : '',
                    password_confirm : '',
                }
            }
        },
        mounted() {
            this.$store.dispatch('loadUserData')
        },
        computed: mapState([
            'userdata'
        ]),
        created() {
            this.getData();
        },
        methods: {
            hide_modal() {
                this.$modal.hide('my-modal')
            },
            matchPassword() {
                if (this.form.password == this.form.password_confirm && this.form.password_confirm != '') {
                    this.checkPassword = 1;
                } else {
                    this.checkPassword = 2;
                }
            },
            getData: function() {
                this.listData = [];
                this.fetching = true;
                let uri = this.$apiconfig + 'mahasiswa/profil-magister';
                this.$http.get(uri, {
                    params: {
                        keyword     : '',
                    }
                }).then(res => {					
                    let getResponse = res.data;
                    if (getResponse.status) {
                        this.profil = getResponse.data;
                    }
                    this.fetching = false;
                });
            },
            submitForm() {
                if(this.form.password == this.form.password_confirm){
                    const formData = new FormData();
                    formData.append('password', this.form.password);

                    this.$http.post(this.baseUrl + 'mahasiswa/profil/save-magister', formData)
                        .then((response) => {
                            if(response.data.status){
                                this.$swal({
                                    icon: 'success',
                                    title: "success",
                                    text: response.data.message,
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                this.form.password = ''
                                this.form.password_confirm = ''
                                localStorage.removeItem('auth_token');
                                localStorage.removeItem('email');
                                axios.defaults.headers.common['Authorization'] = '';        
                                location.reload();
                                this.$router.push('/');
                            }else{
                                this.$swal({
                                    icon: 'error',
                                    title: "Error",
                                    text: response.data.message,
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                            }
                        })
                        .catch(error => {
                            console.log(error.response.data)
                        });
                }else{
                    this.$swal({
                        icon: 'error',
                        title: "Error",
                        text: 'Password tidak sesuai',
                        showConfirmButton: false,
                        timer: 1500
                    }) 
                }
            },
        }
    }
</script>